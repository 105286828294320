import React from "react"
import styled from "styled-components"

import { HSection } from "components/common"

import { device } from "utils"

const HardwareHero = ({ hatIcon, hat, title, subtitle, media }) => {
  const sectionMinHeight = media.desktop.file.details.image.height

  return (
    <StyledHardwareHero sectionMinHeight={sectionMinHeight}>
      <HSection
        hatIcon={hatIcon}
        hat={hat}
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        media={media}
        mobileFull
        full
      />
    </StyledHardwareHero>
  )
}

const StyledHardwareHero = styled.div`
  --section-padding-top: 0;
  --section-padding-bottom: 0;
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-mobile-padding-bottom: 0;
  --tg-hat-color: var(--water);
  --section-max-width: 1080px;

  @media ${device.laptop} {
    margin-bottom: -48px;
    --tg-max-width: 531px;
  }

  .half-side-container {
    .horizontal_textSection {
      @media ${device.laptop} {
        margin-top: 160px;
        /* margin-bottom: 112px; */
        margin-bottom: 200px;
      }
    }

    /* keep full and just manaully shift over?? */
    .hSection-media {
      img {
        @media ${device.laptop} {
          right: 5vw;
        }
        @media ${device.desktop} {
          right: 15vw;
        }
        @media ${device.desktopL} {
          right: 30vw;
        }
      }
    }
  }
`

export default HardwareHero
