import React from "react"
import styled from "styled-components"

import { VerticalSection, Card, Grid } from "components/common"

const HardwareGetStarted = ({ title, valueProps }) => {
  return (
    <StyledHardwareGetStarted>
      <VerticalSection
        align="left"
        title={title}
        grid={() => (
          <Grid
            toEdge
            list={valueProps}
            component={(vp) => <Card background titleAs="h5" {...vp} />}
            align="left"
          />
        )}
      />
    </StyledHardwareGetStarted>
  )
}

const StyledHardwareGetStarted = styled.div`
  --section-max-width: 1080px;
  .gridWrap {
    margin-top: var(--sp-32);
    ul {
      align-items: flex-start;

      li {
        --tg-title-color: var(--dirt);
      }
    }
  }
`

export default HardwareGetStarted
