import React from "react"
import styled from "styled-components"

import { HSection } from "components/common"

import { device } from "utils"

const HardwareIOTBox = ({ hatIcon, hat, title, subtitle, media }) => {
  const sectionMinHeight = media.desktop.file.details.image.height

  return (
    <StyledHardwareIOTBox sectionMinHeight={sectionMinHeight}>
      <HSection
        hatIcon={hatIcon}
        hat={hat}
        title={title}
        titleAs="h2"
        subtitle={subtitle}
        media={media}
      />
    </StyledHardwareIOTBox>
  )
}

const StyledHardwareIOTBox = styled.div`
  --tg-hat-color: var(--water);
  --section-max-width: 1080px;
  --section-padding-bottom: var(--sp-48);

  @media ${device.laptop} {
    --tg-max-width: 544px;
  }
`

export default HardwareIOTBox
