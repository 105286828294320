import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"

import { useContentfulSection, useMeta } from "utils"
import {
  HardwareHero,
  HardwareHighlights,
  HardwareIOTBox,
  HardwareScales,
  // HardwareGetStarted,
} from "src/sections/hardware"
import { CtaGetDemo } from "components/cta"

const Hardware = ({ data: { hardware } }) => {
  const {
    hardware_hero,
    hardware_highlights,
    hardware_iot_box,
    hardware_scales,
    // hardware_get_started,
    cta_get_demo,
  } = useContentfulSection(hardware.sections)

  const meta = useMeta(hardware)

  return (
    <Layout>
      <Seo {...meta} />
      <Nav />

      <Wrap>
        <HardwareHero {...hardware_hero} />
        <HardwareHighlights {...hardware_highlights} />
        <HardwareIOTBox {...hardware_iot_box} />
        <HardwareScales {...hardware_scales} />
        {/* <HardwareGetStarted {...hardware_get_started} /> */}
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div``

export const query = graphql`
  {
    hardware: contentfulPage(pageId: { eq: "hardware" }) {
      ...Page
    }
  }
`

export default Hardware
