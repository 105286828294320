import React from "react"
import styled from "styled-components"

import { VerticalSection, Grid, ValueProp } from "components/common"

import { glassStyle, device } from "utils"

const HardwareHighlights = ({ title, subtitle, valueProps, hat }) => {
  return (
    <StyledHardwareHighlights>
      <VerticalSection
        grid={() => (
          <Grid
            gap={48}
            list={valueProps}
            component={(vp) => (
              <ValueProp titleAs="h5" subtitleAs="pSmall" {...vp} />
            )}
            align="center"
          />
        )}
      />
    </StyledHardwareHighlights>
  )
}

const StyledHardwareHighlights = styled.div`
  position: relative;
  z-index: 4;

  --section-padding-left: var(--sp-16);
  --section-padding-right: var(--sp-16);

  .sectionBackground {
    ${glassStyle};
    border-radius: 0;
  }

  .gridWrap {
    margin-top: 0;
    ul {
      grid-template-columns: repeat(1, 1fr);

      @media ${device.tablet} {
        grid-template-columns: repeat(3, minmax(auto, 236px));
      }

      @media ${device.laptop} {
        max-width: 1080px;
        margin: 0 auto;
        grid-template-columns: repeat(4, 1fr);
      }

      li {
        &:nth-child(1) {
          .title {
            max-width: 228px;
          }
        }
      }

      .subtitle {
        font-weight: normal;
      }
    }
  }
`

export default HardwareHighlights
